.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}
.is-visible {
  opacity: 1;
}
.gallery_container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
}
.gallery_title {
  font-size: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
.gallery_title p {
  color: #000;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.gallery_area {
  width: 85%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gallery_menu {
  height: 20vh;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.gallery_ul,
.gallery_ul1 {
  background-color: #d9d9d9;
  margin: 0;
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.dropdown_icon {
  font-size: 22px;
  display: none;
}
.gallery_ul {
  display: none;
}
/* .dropdown_mobile{
  display: flex;
  flex-direction: row ;
} */
.dropdown_trigger {
  display: none !important;
}
.gallery_ul1 {
  display: flex;
}
.gallery_li {
  font-family: "Montserrat";
  width: 250px;
  height: 10vh;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
  text-align: center;
  display: flex;
}
.activeClass {
  border: 4px solid #000;
  z-index: 10;
}

/* gallery */

.gallery_images {
  padding: 3%;
  max-width: 100%;
  margin: 0 auto;
  background: #d9d9d9;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 250px;
  grid-auto-flow: dense;
  width: 100%;
}
.v-stretch {
  grid-row: span 2;
  background-color: #fff;
}
.h-stretch {
  grid-column: span 2;
  background-color: #fff;
}
.big-stretch {
  grid-row: span 2;
  grid-column: span 2;
  background-color: #fff;
}
.gallery_images div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1330px) {
  .gallery_area {
    width: 90%;
  }
}
@media screen and (max-width: 1024px) {
  .gallery_title {
    height: 40vh;
    align-items: center;
  }
}
@media screen and (max-width: 934px) {
  .gallery_li {
    font-size: 16px;
  }
}
@media screen and (max-width: 768px) {
  .dropdown_trigger {
    display: flex;
  }
  .dropdown_text {
    font-family: "Montserrat", sans-serif;
    margin: 0;
    width: 70%;
  }
  .dropdown_icon {
    display: flex;
  }
  .gallery_ul1 {
    display: none;
  }
  .gallery_area {
    justify-content: center !important;
    display: flex;
    text-align: center;
  }
  .gallery_menu {
    width: 100%;
    height: 20vh;
    justify-content: center;
  }
  .gallery_menu .gallery_ul {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: transparent;
  }
  .dropdown_mobile {
    background-color: transparent;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(130.5%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 5 !important;
  }
  .dropdown_mobile_closed {
    display: none;
  }
  .gallery_menu .gallery_ul .activeClass {
    display: flex !important;
    background-color: #d9d9d9;
    width: 350px !important;
    height: 13vh;
  }
  .gallery_menu .gallery_ul .gallery_li {
    display: flex;
    background-color: #d9d9d9;
    width: 350px;
    font-weight: 600;
    font-size: 20px;
    justify-content: space-evenly;
  }
}
@media (max-width: 600px) {
  .dropdown_mobile {
    transform: translateY(68.3vh);
  }
}
@media (max-width: 560px) {
  .v-stretch {
    grid-row: span 1;
    grid-column: span 2;
  }
  .h-stretch {
    grid-row: span 1;
    grid-column: span 2;
  }
  .big-stretch1 {
    grid-column: span 1;
  }
  .gallery_menu .gallery_ul .activeClass {
    width: 300px !important;
  }
  .gallery_li {
    width: 300px !important;
  }
}
@media screen and (max-width: 425px) {
  .gallery_li {
    font-size: 14px;
  }
  .activeClass {
    width: 300px !important;
    border: 3px solid #000;
  }

  .gallery_title p {
    font-size: 45px;
  }
  .gallery_title {
    height: 30vh;
  }
  .dropdown_mobile {
    transform: translateY(57.5vh);
  }
}

@media screen and (max-width: 320px) {
  .gallery_menu .gallery_ul .activeClass,
  .gallery_li {
    width: 290px !important;
  }
}
