@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0px;
    padding: 0px;
    font-family: 'Raleway', sans-serif;
}

.ratespage{
    width: 100vw;
    height: max-content;
}

.ratespage #crates ,.ratespage h5 {
    width: 100vw;
    height: max-content;
    margin-left: 19vw;
    color: #000;
    font-weight: 700;
}

.cardsgrid{
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    gap: 15vh;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: max-content;
}

.cardsgrid .row{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    width: 100vw;
    height: max-content;
}

.cardsgrid .row1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    width: max-content;
    height: max-content;
}

.cardsgrid .cards{
    display: flex;
    gap: 3vw;
    align-items: center;
    width: 82vw;
    height: max-content;
}

.cardsgrid .row2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    width: 100vw;
    height: max-content;
}

.ratespage h5{
    margin-top: 10vh;
    color: #000;
    font-weight: 700;
    font-size: 17px;
    position: relative;
    right: 1vw;
}

.quotationbox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 9vh;
    background-color: rgb(254, 193, 25);
    padding-bottom: 2.15vh;
    margin-top: 30vh;
}

.quotationbox h2 a{
    font-family: 'Raleway', sans-serif;
    font-size: 2.5rem;
    font-weight: 700;
    color: black;
}


.quotationbox img{
    width: 2.5rem;
}

@media screen and (max-width:768px) {
    .cardsgrid .cards{
        gap: 4vh;
        flex-direction: column;
    }

    .cardsgrid .row{
        gap: 8vw;
        flex-direction: column;
    }

    .cardsgrid .row1{
        flex-direction: column;
        gap: 4vh;
        margin-top: 5vh;
    }

    .ratespage #crates{
        font-size: 2rem;
        font-weight: 800;
        margin-left: 0vw;
        text-align: center;
        margin-top: 12vh;
    }

    .ratespage h5{
        color: #000;
        font-weight: 700;
        font-size: 1.5rem;
        position: relative;
        left: 5vw;
    }

    .cardsgrid{
        margin-top: -3vh;
        gap: 0vh;
    }

    .quotationbox{
        margin-top: 5vh;
    }

    .quotationbox h2 a{
        font-size: 1.8rem;
    }

    .quotationbox img{
        width: 2rem;
    }

    #jamesknowsbetter{
        display: none;
    }

    #jamesdoesntknowsbetter{
        position: relative;
        bottom: 4vh;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {

    .ratebox .rateinfo{
        height: 12vh;
    }

    .quotationbox{
        margin-top: 15vh;
        height: 7vh;
    }

    .rateinfo h3 {
        font-weight: 800;
    }
}