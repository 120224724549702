@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal{
    width: max-content;
    height: max-content;
    display: block;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 60vw;
    min-width: 40vw;
}

.modal-content h3 ,.modal-content p{
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-weight: 500;
}

.modal-content h3{
    font-weight: 700;
}


.close-modal {
    cursor: pointer;
    font-family: 'Raleway', sans-serif;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    width: 28px;
    opacity: 0.7;
    font-weight: 700;
}

@media screen and (max-width:768px) {
    .modal-content{
        min-width: 80vw;
    }
}