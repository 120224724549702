.head_div {
  width: 100%;
  height: 70px;
  background-color: #090909;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 30 !important;
  position: relative;
}
.head_left {
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 20%;
}
.hourly-nav {
  display:flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
  gap:10px;
}
.head_right {
  width: max-content;
  height: 100%;
  gap: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 5%;
}
.head_logo {
  width: 50px;
  height: 40px;
}
.head_title {
  font-size: 35px;
  color: #fff;
  /* text-transform: capitalize; */
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0;
}
.head_title1 {
  font-weight: 500;
  font-size: 25px;
  color: #fff;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}
.phone_icon {
  color: #fff;
  font-size: 25px;
  margin: 0;
}
.nav {
  background: #fff;
  box-shadow: 0px 4px 50px -7px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  position: relative;
}
.link {
  text-decoration: none !important;
  color: #000;
}
.link:hover{
  color: #000 !important;
}
.nav_ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  color: #000;
  width: 90%;
  height: 100%;
  list-style: none;
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0;
}
.logo_link,.call{
  text-decoration: none !important;
}
li {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fa-bars,
.nav_mobile,
.menu-bar {
  display: none;
}
.nav_mobile {
  z-index: 50;
}
.number_mobile{
  font-family: "Montserrat", sans-serif;
}
.nav_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  top: 70px;
  left: 0;
  height: 70vh;
  width: 100%;
  z-index: 20 !important;
  transition-duration: 1.5s;
  transform: translateY(-150%);
}
@media screen and (max-width: 768px) {

  .head_left,
  .head_right {
    width: auto;
  }
  .head_title {
    font-size: 30px;
  }
  .head_title1,
  .phone_icon {
    font-size: 25px;
  }
  .nav_ul {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .hourly-nav{
    display: none;
  }
  .nav {
    display: none;
  }
  .nav_mobile_open {
    display: flex;
    transform: translateY(0%);
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  }
  .phone_icon,
  .head_title1 {
    display: none;
    color: #000 !important;
  }
  .head_right {
    width: 130px !important;
    margin-right: 0%;
  }
  .fa-bars,
  .fa-bars-staggered,
  .menu-bar {
    display: block;
    color: #fff;
    font-size: 30px;
  }
  .ul_mobile {
    background-color: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);

  }
  .li_mobile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    font-size: 25px;
  }
  .link_mobile {
    color: #000;
    text-decoration: none !important;
    font-weight: 600;
  }
  .number_mobile {
    margin: 0;
    font-weight: 600;
    color: #090909 !important;
  }
  .call:hover{
    color: #090909 !important; 
  }
  .head_left{
    margin-left: 5%;
  }
  .head_logo{
    width: 50px;
    height: 40px;
  }
}
@media screen and (max-width: 550px) {
  .hourly-nav{
    display: none;
  }
  .head_left{
    width: 190px;
  }
  .head_right {
    width: 100px !important;
  }
  .head_title {
    font-size: 25px;
  }
  .head_title1,
  .phone_icon {
    font-size: 20px;
  }
  .phone_icon_mobile{
    color: #090909 !important;
  }
  .head_logo {
    width: 40px;
    height: 30px;
  }
}
@media screen and (max-width: 375px){
  .hourly-nav{
    display: none;
  }
  .head_right {
    width: 80px;
  }
}