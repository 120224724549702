* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#ab {
  position: relative;
  top: 150px;
  font-family: Raleway;
  text-align: center;
  color: #000000;
  opacity: 1;
  font-weight: bolder;
  font-size: 50px;
}

.cimg1 {
  width: 100%;
  height: 350px;
}

.colors {
  position: relative;
  top: -410px;
  width: 100%;
  height: 520px;
  opacity: 0.9;
  background: #ffc736;
}

.logoo2 {
  position: absolute;
  top: 50px;
  width: 700px;
  height: 700px;
  left: -260px;
}

.box1 {
  position: relative;
  top: -540px;
  margin-left: 140px;
  width: auto;
  margin-right: auto;
  height: 150px;
  background-color: #ffffff;
}

.circles {
  position: relative;
  width: 270px;
  height: 270px;
  top: -690px;
  background: #ffffff;
  border-radius: 50%;
}

.desk {
  position: relative;
  top: -865px;
  font-family: Raleway;
  font-size: 38px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  color: #000000;
}

.underline {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  left: 88px;
  width: 248px;
  height: 7px;
  top: -780px;
  background: linear-gradient(
    90deg,
    rgba(250, 158, 21, 1) 33%,
    rgba(197, 4, 64, 1) 32%
  );
}

.mssg1 {
  position: relative;
  text-align: justify;
  top: -800px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg2 {
  position: relative;
  top: -770px;
  text-align: justify;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg3 {
  text-align: justify;
  position: relative;
  top: -740px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg4 {
  text-align: justify;
  position: relative;
  top: -710px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg5 {
  text-align: justify;
  position: relative;
  top: -680px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg6 {
  text-align: justify;
  position: relative;
  top: -640px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.mssg7 {
  text-align: justify;
  position: relative;
  text-align: right;
  top: -600px;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  width: 680px;
  margin: auto;
  color: #000000;
}

.partation {
  position: relative;
  width: auto;
  top: -530px;
  margin: 0 90px 0 90px;
  border: 1.99px;
  opacity: 0.4;
  border: 0.99px solid #000000;
}

.heading {
  position: relative;
  text-align: center;
  top: -485px;
  font-family: Raleway;
  font-size: 24px;
  color: #000000;
}

.heading h1 {
  font-weight: bold;
}

.heading p {
  text-align: justify;
  display: flex;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  width: 650px;
}

.line1 {
  position: relative;
  width: auto;
  top: 70px;
  margin: 0 90px 0 90px;
  border: 1.99px;
  opacity: 0.4;
  border: 0.99px solid #000000;
}

.heading2 {
  position: relative;
  text-align: center;
  top: -385px;
  font-family: Raleway;
  font-size: 22px;
  color: #000000;
}

.heading2 h1 {
  font-weight: bold;
}

.heading2 p {
  text-align: justify;
  display: flex;
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  width: 670px;
  font-family: Raleway;
}

.line2 {
  position: relative;
  width: auto;
  top: 70px;
  margin: 0 90px 0 90px;
  border: 1.99px;
  opacity: 0.4;
  border: 0.99px solid #000000;
}

.heading3 {
  position: relative;
  text-align: center;
  top: -280px;
  font-family: Raleway;
  font-size: 22px;
  line-height: 80;
  color: #000000;
}

.heading3 h1 {
  font-weight: bold;
}

.heading3 h5 {
  line-height: 50px;
}

.heading3 h5 {
  font-weight: 600;
}

.about-us {
  position: relative;
  top: -218px;
  line-height: 40px;
}

.img2 {
  width: 32px;
  height: 32px;
}

.heaa1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.heaa1 .card1 {
  list-style: none;
  display: block;
}

.card1 h5 {
  margin-left: -35px;
  line-height: 0;
  padding-top: 20px;
  font-weight: 700;
}

.card1 h6 {
  width: 230px;
  margin-left: -90px;
  font-weight: 600;
  text-align: center;
}

#deter {
  margin-right: -90px;
}

.about-us2 {
  position: relative;
  top: -150px;
  line-height: 30px;
}

.heaa2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.heaa2 .card2 {
  list-style: none;
  display: block;
}

.card2 h5 {
  margin-left: -28px;
  line-height: 0;
  padding-top: 30px;
  font-weight: 700;
}

.card2 h6 {
  width: 250px;
  margin-left: -90px;
  font-weight: 600;
  text-align: center;
}

#rep h5 {
  margin-left: -12px;
}

#rep {
  margin-right: -100px;
}

.about-us3 {
  position: relative;
  top: -75px;
  line-height: 30px;
}

.heaa3 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.heaa3 .card3 {
  list-style: none;
  display: block;
}

.card3 h5 {
  margin-left: -28px;
  line-height: 0;
  padding-top: 30px;
  font-weight: 700;
}

#cont {
  margin-left: -70px;
}

.card3 h6 {
  width: 250px;
  margin-left: -90px;
  font-weight: 600;
  text-align: center;
}

#con {
  margin-right: -80px;
}

.about-us4 {
  position: relative;
  line-height: 30px;
}

.heaa4 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
}

.heaa4 .card4 {
  list-style: none;
  display: block;
}

.card4 h5 {
  margin-left: -10px;
  line-height: 0;
  padding-top: 30px;
  font-weight: 700;
}

.card4 h6 {
  width: 250px;
  margin-left: -90px;
  font-weight: 600;
  text-align: center;
}

#acc h5 {
  margin-left: -28px;
}

#acc {
  margin-right: -90px;
}

.last-line h6 {
  position: relative;
  text-align: center;
  margin: auto;
  font-family: Raleway;
  top: 115px;
  font-size: 14px;
  width: 700px;
  font-weight: 600;
  margin-bottom: 200px;
}

.line3 {
  position: relative;
  width: auto;
  top: 30px;
  margin: 0 90px 0 90px;
  border: 1.99px;
  opacity: 0.4;
  border: 0.99px solid #000000;
  margin-bottom: 200px;

}

.Social-heading{
  position: relative;
font-family: Raleway;
text-align: center;
top: -60px;
color: #000000;

}

.Social-heading h1{
  font-weight: 700;

}


.public-service{
  height: 52px;
  width: 52px;
}

.socialA{
font-family: Raleway;
font-size: 14px;
font-weight: 500;
letter-spacing: 0em;
text-align: center;
width: 793px;
color: #000000;
margin: auto;
}
.socialA p,.socialB p, .socialC p {
  text-align: justify;
}

.socialB{
  position: relative;
display: block;
font-family: Raleway;
font-size: 14px;
font-weight: 500;
text-align: center;
width: 793px;
color: #000000;
top: 60px;
margin: auto;
margin-bottom: 200px;
}

.socialC{
position: relative;
font-family: Raleway;
font-size: 14px;
font-weight: 500;
letter-spacing: 0em;
text-align: center;
width: 793px;
color: #000000;
margin: auto;
top: -50px;

}

@media (max-width: 830px) {
  #rep {
    margin-right: -80px;
  }

  #con {
    margin-right: -80px;
  }

  .social-mssg .socialA, .socialB, .socialC{
    font-size: 13px;
    width: 620px;

  }
}

@media (max-width: 550px) {
  .logoo2 {
    display: none;
  }

  .card1 h5 {
    padding: 0;
    margin-left: -26px;
  }

  .card1 h6 {
    width: 185px;
  }

  .about-us {
    top: -250px;
  }

  .about-us .heaa1 {
    margin-left: 20px;
  }

  .about-us #deter {
    margin-top: 80px;
    margin-right: -81px;
  }

  .about-us2 {
    top: -310px;
    margin-left: 100px;
  }

  .heaa2 {
    flex-direction: column;
  }

  .card2 h5 {
    padding: 0;
  }

  .card2 h6 {
    width: 182px;
  }

  .about-us2 #commit {
    margin-top: -20px;
    margin-left: 173px;
  }

  .about-us3 {
    top: -350px;
    margin-left: 280px;
  }

  .heaa3 {
    flex-direction: column;
  }

  .card3 h5 {
    padding: 0;
  }

  .card3 h6 {
    width: 188px;
  }

  .about-us3 #innova {
    margin-left: -180px;
  }

  #innova h6 {
    font-size: 11px;
  }

  #con {
    margin-left: -17px;
    margin-top: -10px;
  }

  #coll {
    margin-left: -12px;
  }

  #cont {
    margin-left: -80px;
  }

  .card4 h5 {
    padding: 0;
    margin-left: -13px;
  }

  #acc h5 {
    margin-left: -30px;
  }

  .card4 h6 {
    width: 185px;
  }

  .about-us4 {
    top: -350px;
  }

  .about-us4 .heaa4 {
    margin-left: 20px;
  }

  .about-us4 #acc {
    margin-top: 80px;
    margin-right: -57px;
  }

  .last-line h5 {
    width: 400px;
    font-size: 10px;
  }

  .heading p {
    font-size: 12px;
    width: 347px;
  }

  .heading2 p {
    font-size: 12px;
    width: 361px;
  }

  .last-line h6 {
    margin-top: -420px;
    width: 355px;
    font-size: 12px;
  }

  .desk {
    font-size: 28px;
  }

  .underline {
    left: 64px;
    width: 183px;
  }

  
  .message-from .mssg1,.mssg2,.mssg3,.mssg4,.mssg5,.mssg6,.mssg7{
    font-size: 12px;
    width: 356px;
  
  }

  .social-mssg .socialA, .socialB, .socialC{
    font-size: 12px;
    width: 350px;
  
  }
}

@media (max-width: 300px) {
  .desk {
    font-size: 20px;
  }

  .underline {
    left: 46px;
    width: 131px;
  }

  
.message-from .mssg1,.mssg2,.mssg3,.mssg4,.mssg5,.mssg6,.mssg7{
  font-size: 10px;
  width: 265px;

}

.heading p, .heading2 p{
  font-size: 10px;
  width: 253px;
}

.last-line h6 {
  width: 229px;
  font-size: 10px;
}

.social-mssg .socialA, .socialB, .socialC{
  font-size: 9px;
  width: 265px;

}


}
